import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/homely-005.png"
import GetInTouch from "../components/molecules/GetInTouch"

function HomelyHomes() {
  return (
    <>
      <Seo
        title="Establishing care principles that create a sense of home | Craig Walker x Anglicare"
        image={thumb}
        description="Moving into residential aged care is a significant moment in the life of elderly people and their families."
      />

      <Splash
        text={<>Establishing care principles that create a sense of home</>}
        work={true}
      >
        <p>
          <b>
            Moving into residential aged care is a significant moment in the
            life of elderly people and their families. This project explored
            ways of protecting, nurturing and fostering a sense of home and self
            for people moving into and already living in residential care with
            Anglicare.
          </b>
        </p>
        <p>
          Anglicare was eager to further improve the environment and care they
          provide in order to create a greater sense of home for residents.
          Anglicare asked Craig Walker to research and develop design
          recommendations that could be implemented easily. We captured and
          amplified the voices and lived experiences of residents, identified a
          number of opportunity areas and created simple but effective design
          recommendations.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="anglicare-1.png" />
        </WorkImage>

        <Numbers>
          <Number
            number={45}
            text="Interviews with residents, staff and family "
          />
          <Number number={3} text="Co-design workshops" />
          <Number
            number="30+"
            text="Ideas formed from key opportunity spaces"
          />
        </Numbers>

        <WorkTextBlock>
          <h2>Principles that underpin a homelike experience</h2>
          <p>
            Our research and conversations with aged care staff, residents,
            family members consistently surfaced four fundamental principles
            that are essential to creating more homelike residential care:
            familiarity, purpose, agency and respect. Together they provide a
            holistic foundations for the wellbeing of residents.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="anglicare-2.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Research homes, from home</h2>
          <p>
            A COVID-19 lockdown meant that all communication and research was
            performed digitally over voice and video call. Despite the challenge
            in adapting, this way of working highlighted the importance of
            connection and communication for residents who are separated from
            family.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="anglicare-3.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Capturing the voices of the people who matter</h2>
          <p>
            As part of this project, we developed a number of representative
            portraits involving residential care and retirement living residents
            as well as family and staff. These are anonymised reflections of
            real people we spoke with. The portraits primarily serve as a means
            of giving life to the voices of the residents. Each portrait is a
            powerful tool in conveying the thoughts and experiences of
            individuals and create a valuable asset in future iteration in
            projects around this topic.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="anglicare-4.jpg" />
        </WorkImage>
        <WorkTextBlock>
          <h2>Opportunity areas and ideation</h2>
          <p>Through research, we developed four major opportunity areas:</p>
          <ul>
            <li>Physical Space</li>
            <li>How I Spend My Time</li>
            <li>Thriving At Home</li>
            <li>The Organisation</li>
          </ul>
          <p>
            The ideas developed within these opportunity areas aimed to prove
            design recommendations, ranging from extremely simple to complex and
            detailed. The team wanted to ensure a greater likelihood of uptake
            and provide immediate relief to some basic issues that can
            fundamentally change a person’s sense of home.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="anglicare-5.png" />
        </WorkImage>
        <GetInTouch dataView="FrameWork" />
        <RelatedWork exclude="www-f" />
      </ContentHolder>
    </>
  )
}

export default HomelyHomes
